<template>
  <div class="card card-body pb-1 ">
    <status-filter :filters="filters"></status-filter>
  </div>
</template>

<script>
import StatusFilter from "./SidebarFilters/StatusFilter";
export default {
  name: "SidebarFilters",
  props: ['filters'],
  components: {StatusFilter}
}
</script>

<style scoped>

</style>