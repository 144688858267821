<template>
  <div class="card bg-secondary card-hover">
    <div class="card-body">
      <div class="flex-wrapper">
        <div class="left">
          <h5 class="card-title mb-3">{{ revenue.job ? revenue.job.title : 'Unknown job' }}</h5>
          <div class="date-details mb-2">
            <div>
              <b>Date:</b>
              {{ formatDate(revenue.created_at) }}
            </div>
          </div>
          <div class="amount-details mb-2">
            <div>
              <b>Amount:</b>
              {{ formatCostInPence2dp(revenue.amount) }}
            </div>
          </div>
          <div class="client-details mb-2">
            <div>
              <b>Client Name:</b> {{ (revenue.job && revenue.job.client_user_role) ? revenue.job.client_user_role.name : '-' }}
            </div>
            <div>
              <b>Company Name:</b> {{
                (revenue.job && revenue.job.client_user_role && revenue.job.client_user_role.company_name) ? revenue.job.client_user_role.company_name : '-'
              }}
            </div>
          </div>
<!--          <div class="invoice-details">-->
<!--            <div>-->
<!--              <b>Invoice Number:</b> {{ revenue.invoice_number ? revenue.invoice_number : '-' }}-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="right">
          <span class="badge bg-primary" v-if="revenue.paid">Paid</span>
          <span class="badge bg-danger" v-else>Unpaid</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Dates from "../../../mixins/Dates";
import Display from "../../../mixins/Display";

export default {
  name: "RevenueCard",
  props: ['revenue'],
  mixins: [Dates, Display],
  methods: {
    goToJob() {
     this.$router.push('/jobs/' + this.revenue.job_id);
    }
  }
}
</script>

<style scoped lang="scss">
.card {
  //cursor: pointer;
  font-size: 15px;

  .client-details, .date-details, .amount-details, .invoice-details {
    div {
      display: inline-block;

      &:first-child {
        padding-right: 20px;
      }

      b {
        color: #1f1b2d;
      }
    }
  }

  .flex-wrapper {
    display: flex;

    .left {
      flex-grow: 1;
    }

    .right {
      flex-shrink: 1;
      display: flex;
      align-items: center;
    }
  }
}
</style>