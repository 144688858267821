<template>
  <div>
    <!-- Page Header -->
    <section class="bg-dark pt-5 pb-4">
      <div class="container py-5">
        <h1 class="text-light pt-1 pt-md-3 mb-4">{{ isAdvisor ? 'Coordination Fees' : 'Revenue' }}</h1>
      </div>
    </section>
    <!-- / Page Header -->

    <!-- Results -->
    <section class="position-relative bg-white rounded-xxl-4 zindex-5" style="margin-top: -30px;">
      <div class="container pt-4 pb-5 mb-md-4">
        <!-- Breadcrumb-->
        <breadcrumb :crumbs="crumbs" class="pb-4 my-2"></breadcrumb>

        <!-- Row -->
        <div class="row">
          <div class="col-md-3">
            <sidebar-filters :filters="filters"></sidebar-filters>
          </div>
          <div class="col-md-9">
            <!-- Is Loading -->
            <div v-if="isLoading" class="text-center">
              <spinner></spinner>
            </div>
            <!-- / Is Loading -->

            <!-- No results -->
            <no-results v-if="!isLoading && revenuesToShow.length === 0">
              <!--              No jobs that you've referred have been completed yet-->
              No results to show
            </no-results>
            <!-- / No results -->

            <!-- Results -->
            <div v-if="!isLoading && revenuesToShow.length">
              <revenue-card v-for="(revenue, i) in revenuesToShow" :key="i"
                            class="mb-3"
                            :revenue="revenue"
              ></revenue-card>

              <!-- Pagination -->
              <!--              <div class="mt-5" v-if="results && results.last_page > 1">-->
              <!--                <pagination v-model="page" :records="results.total" :per-page="results.per_page" @paginate="fetch"/>-->
              <!--              </div>-->
              <!-- / Pagination -->
            </div>
            <!-- / Results -->
          </div>
        </div>
        <!-- / Row -->
      </div>
    </section>
    <!-- / Results -->
  </div>
</template>

<script>
import Breadcrumb from "../../components/Breadcrumb";
import Spinner from "../../components/Spinner";
import NoResults from "../../components/NoResults";
import Forms from "../../mixins/Forms";
import SidebarFilters from "./Index/SidebarFilters";
import RevenueCard from "./Index/RevenueCard";
import {mapGetters} from 'vuex';
const _ = require('lodash');

export default {
  name: "Index",
  mixins: [Forms],
  components: {RevenueCard, Breadcrumb, Spinner, NoResults, SidebarFilters},
  data() {
    return {
      crumbs: [
        {
          href: "/",
          label: "Home",
        },
        {
          href: "#",
          label: "Revenue",
          active: true,
        },
      ],
      filters: {},
      serverErrors: [],
    }
  },
  computed: {
   ...mapGetters('auth', {
     isAdvisor: 'isAdvisor'
   }),

    ...mapGetters('revenues', {
      isLoading: 'isLoading',
      revenues: 'revenues'
    }),

    revenuesToShow() {
      if (this.revenues) {
        let revenues = this.revenues;

        if (this.filters.paid && this.filters.paid !== null) {
          revenues = _.filter(revenues, r => {
            return Boolean(r.paid) === Boolean(this.filters.paid);
          });
        }
        return _.orderBy(revenues, 'created_at', 'desc');
      }
      return [];
    }
  },
  methods: {}
}
</script>

<style scoped lang="scss">
</style>