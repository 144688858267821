const _ = require('lodash');

export default {
    data() {
        return {
            revenueFilterOptions: [
                {
                    value: null,
                    label: 'View All',
                    icon: 'fi-cash'
                },
                {
                    value: 0,
                    label: 'Pending',
                    icon: 'fi-clock'
                },
                {
                    value: 1,
                    label: 'Paid',
                    icon: 'fi-check'
                }
            ]
        }
    }
}