<template>
  <div class="status-filter-wrapper">
    <h4 class="mb-3 h5">Filter By Status</h4>
    <ul class="list-unstyled fs-sm">
      <li v-for="option in revenueFilterOptions">
        <a class="nav-link fw-normal d-flex align-items-center px-0 py-1"
           :class="{'active': isSelected(option)}"
           href="#" @click.prevent="selectOption(option)">
          <i :class="getIconClass(option)"></i>
          <span>{{ option.label }}</span>
<!--          <span class="text-muted ms-auto">(3)</span>-->
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import Revenues from "../../../../mixins/Revenues";
export default {
  name: "StatusFilter",
  props: ['filters'],
  mixins: [Revenues],
  data() {
    return {
      filterKey: 'paid'
    }
  },
  methods: {
    isSelected(option) {
      return this.filters[this.filterKey] == option.value;
    },
    selectOption(option) {
      this.filters[this.filterKey] = option.value;
    },
    getIconClass(option) {
      return option.icon + ' opacity-70 me-2';
    }
  }
}
</script>

<style scoped>
.status-filter-wrapper {
  max-width: 15rem;
}
</style>